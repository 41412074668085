import * as React from "react";
import { Link } from "gatsby";
import { useEffect } from 'react';

import c1 from '../blog/img/tattoo/1.png';
import c2 from '../blog/img/tattoo/2.png';
import c3 from '../blog/img/tattoo/3.png';
import c4 from '../blog/img/tattoo/4.png';
import c5 from '../blog/img/tattoo/5.png';
import c6 from '../blog/img/tattoo/6.png';
import c7 from '../blog/img/tattoo/7.png';
import c8 from '../blog/img/tattoo/8.png';
import c9 from '../blog/img/tattoo/9.png';
import c10 from '../blog/img/tattoo/10.png';
import c11 from '../blog/img/tattoo/11.png';
import c12 from '../blog/img/tattoo/12.png';
import c13 from '../blog/img/tattoo/13.png';
import c14 from '../blog/img/tattoo/14.png';
import c15 from '../blog/img/tattoo/15.png';
import c16 from '../blog/img/tattoo/16.png';
import c17 from '../blog/img/tattoo/17.png';
import c18 from '../blog/img/tattoo/18.png';
import c19 from '../blog/img/tattoo/19.png';
import c20 from '../blog/img/tattoo/20.png';
import c21 from '../blog/img/tattoo/21.png';
import c22 from '../blog/img/tattoo/22.png';
import c23 from '../blog/img/tattoo/23.png';
import c24 from '../blog/img/tattoo/24.png';

import c25 from '../blog/img/tattoo/25.png';
import c26 from '../blog/img/tattoo/26.png';
import c27 from '../blog/img/tattoo/27.png';
import c28 from '../blog/img/tattoo/28.png';
import c29 from '../blog/img/tattoo/29.png';
import c30 from '../blog/img/tattoo/30.png';
import c31 from '../blog/img/tattoo/31.png';
import c32 from '../blog/img/tattoo/32.png';
import c33 from '../blog/img/tattoo/33.png';
import c34 from '../blog/img/tattoo/34.png';
import c35 from '../blog/img/tattoo/35.png';
import c36 from '../blog/img/tattoo/36.png';
import c37 from '../blog/img/tattoo/37.png';
import c38 from '../blog/img/tattoo/38.png';
import c39 from '../blog/img/tattoo/39.png';
import c40 from '../blog/img/tattoo/40.png';
import c41 from '../blog/img/tattoo/41.png';
import c42 from '../blog/img/tattoo/42.png';
import c43 from '../blog/img/tattoo/43.png';


import c44 from '../blog/img/tattoo/24.png';
import c45 from '../blog/img/tattoo/25.png';
import c46 from '../blog/img/tattoo/26.png';
import c47 from '../blog/img/tattoo/27.png';
import c48 from '../blog/img/tattoo/28.png';
import c49 from '../blog/img/tattoo/29.png';
import c50 from '../blog/img/tattoo/30.png';
import c51 from '../blog/img/tattoo/31.png';
import c52 from '../blog/img/tattoo/32.png';
import c53 from '../blog/img/tattoo/33.png';
import c54 from '../blog/img/tattoo/34.png';
import c55 from '../blog/img/tattoo/35.png';
import c56 from '../blog/img/tattoo/36.png';
import c57 from '../blog/img/tattoo/37.png';
import c58 from '../blog/img/tattoo/38.png';
import c59 from '../blog/img/tattoo/39.png';
import c60 from '../blog/img/tattoo/40.png';
import c61 from '../blog/img/tattoo/41.png';



import { StaticImage } from "gatsby-plugin-image";

import '../components/tattoo.css';

// styles
const pageStyles = {
  color: "#232129",
  padding: 6,
  fontFamily: "-apple-system, Roboto, sans-serif, serif",
}

const headingStyles = {
  marginTop: 0,
  marginBottom: 34,
  // maxWidth: 320,
}

const C = [c1, c2, c3, c4, c5, c6, c7, c8, c9, c10,
c11, c12, c13,c14,c15,c16,c17,c18,c19,c20,c21,c22,c23,c24,c25,
c26,c27,c28,c29,c30,c31,c32,c33,c34,c35,c36,c37,c38,c39,c40,
c41,c42,c43,c44,c45,c46,c47,c48,c49,c50,c51,c52,c53,c54,c55,c56,
c57,c58,c59,c60,c61]

function changeImage() {
  var num = Math.floor(Math.random() * 61);
  var s = C[num];
  document.getElementById("tattoo_index").setAttribute('src', s) ;
}

// markup
const IndexPage = () => {
  return (
    <main style={pageStyles}>
      <title>Tattoo Inspiration</title>
      <h1 style={headingStyles}>
        Tattoos Inspiration - generated by AI
      </h1>


      <div style={{'display': 'flex', 'flex-direction': 'column'}}>
      <img id="tattoo_index" alt="Tattoo Inspiration" src={c33} style={{'margin': 'auto'}} placeholder="blurred"
      layout="fixed"
      width={512}
      height={512}
      onClick={changeImage} />

      <div id="changeImg" style={{'textAlign':'center'}} onClick={changeImage}>Click on Image to generate next Tattoo Inspiration</div>
      </div>  


     
    </main>
  )
}

export default IndexPage
